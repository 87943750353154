import "core-js/modules/es.array.push.js";
export default {
  methods: {
    goToWalletHistory() {
      this.$router.push(`/wallet/wallet-history`).catch(err => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
    },
    goToBundleHistory() {
      this.$router.push(`/wallet/bundle-history`).catch(err => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
    },
    goToWallets() {
      this.$router.push(`/wallet`).catch(err => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
    },
    checkRoute() {
      // dynamic color change
      if (this.$route.path === '/wallet/wallet-history') {
        this.$refs.goToWalletHistory.classList.add('is-active');
      } else if (this.$route.path === '/wallet/bundle-history') {
        this.$refs.goToBundleHistory.classList.add('is-active');
      } else if (this.$route.path === '/wallet') {
        this.$refs.goToWallets.classList.add('is-active');
      }
    }
  },
  mounted() {
    this.checkRoute();
  }
};