<template>
  <div>
    <div class="main-tab">
      <WalletTab></WalletTab>
    </div>
    <div class="search-container">
      <span
        ><el-input
          v-model="search"
          size="large"
          :placeholder="$t('type-to-search')"
          clearable
      /></span>
      <el-button
        type="primary"
        class="export-button"
        v-bind:disabled="filteredTable.length === 0"
        :loading="button_loading"
        @click="exportBundleHistory">
        {{ $t("dashboard.contacts.export") }}
      </el-button>
    </div>
    <div class="col-md-12" v-if="loading">
      <div class="row pt-5">
        <div class="col-md-12" v-loading="loading"></div>
      </div>
    </div>
    <div class="table-container" v-if="!loading">
      <el-table
        :height="dynamicHeight"
        :data="filteredTable"
        header-row-style="font-size:small">
        <!-- Balance Before Deposit -->
        <el-table-column :label="$t('balance-before-bundle')" width="240px">
          <template slot-scope="scope">
            {{
              scope.row.type == 1
                ? scope.row.bundle_tracker.sms_balance?.toString()
                : scope.row.bundle_tracker.voice_balance
            }}
          </template>
        </el-table-column>
        <!-- SMS BONUS -->
        <el-table-column :label="$t('sms-bonus')" width="220px">
          <template slot-scope="scope">
            {{
              scope.row.type == 1
                ? scope.row.bundle_tracker.sms_bonus.toString()
                : "-"
            }}
          </template>
        </el-table-column>
        <!--  BALANCE -->
        <el-table-column :label="$t('quantity')" width="200px" prop="balance">
          <template slot-scope="scope">
            {{ scope.row.balance.toLocaleString() }}
          </template>
        </el-table-column>
        <!-- Amount -->
        <el-table-column :label="$t('dashboard.wallet.amount')" width="200px">
          <template slot-scope="scope">
            {{ processAmount(scope.row.amount) || 0 }}
          </template>
        </el-table-column>
        <!-- Payment method -->
        <el-table-column
          :label="$t('payment-method')"
          width="200px"
          prop="payment_method">
        </el-table-column>
        <!-- Type -->
        <el-table-column :label="$t('bundle-type')" width="200px">
          <template slot-scope="scope">
            <p
              style="margin: 0 auto; color: #00b4d8"
              v-if="scope.row.message_type == 'SMS' && scope.row.type == 1">
              SMS
            </p>
            <p
              style="margin: 0 auto; color: #f7921c"
              v-else-if="
                scope.row.message_type == 'Voice' && scope.row.type == 2
              ">
              {{ $t("misc.voice") }}
            </p>
          </template>
        </el-table-column>
        <!-- Top Up By -->
        <el-table-column :label="$t('bundle-by')" width="200px">
          <template slot-scope="scope">
            {{ scope.row.bundle_by }}
          </template>
        </el-table-column>
        <!-- Wallet -->
        <el-table-column :label="$t('wallet-id')" width="200px">
          <template slot-scope="scope">
            {{ scope.row.bundle_tracker.wallet.toString() }}
          </template>
        </el-table-column>
        <!-- Purchase Date -->
        <el-table-column
          :label="$t('purchase-date')"
          width="200px"
          prop="created_at">
          <template slot-scope="scope">
            {{ moment(scope.row.created_at).format("ll").toString() }}
          </template>
        </el-table-column>
      </el-table>
      <div class="" style="text-align: center; margin-top: 2em">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import WalletTab from "./WalletTab.vue";
  import store from "@/state/store.js";
  import moment from "moment";
  import downloadcsv from "@/helpers/downloadcsv";
  import Storage from "../../../services/storage";
  var storage = new Storage();
  export default {
    data() {
      return {
        bundleHistory: [],
        page: 1,
        pageSize: 10,
        total: 0,
        search: "",
        loading: false,
        button_loading: false,
      };
    },
    components: {
      WalletTab,
    },
    methods: {
      processAmount(amount) {
        const parsedAmount = Number(amount);
        const country = storage.getSavedState("currentUser").country;
        const isFrancophoneCountry = country === "CI" || country === "SN";
        return parsedAmount.toLocaleString(
          isFrancophoneCountry ? "fr-CI" : "en-US",
          {
            style: "currency",
            currency: isFrancophoneCountry ? "CFA" : "GHS",
          }
        );
      },
      handleCurrentChange(val) {
        this.page = val;
      },
      exportBundleHistory() {
        // this.exportLoading = true
        this.button_loading = true;
        store
          .dispatch("wallet/exportBundleHistory", this.$route.params.id)
          .then((data) => {
            downloadcsv(
              data,
              `${localStorage
                .getItem("userName")
                .replace(/ /g, "_")}_Bundle_History_.csv`
            );
            this.button_loading = false;
          })
          .finally(() => (this.button_loading = false));
      },
    },
    computed: {
      getBundleHistory() {
        return this.bundleHistory;
      },
      filteredTable() {
        return this.bundleHistory.filter((item) => {
          return (
            // item.ref_id.toLowerCase().includes(this.search.toLowerCase()) ||
            // item.payment_date.toLowerCase().includes(this.search.toLowerCase()) ||
            item.amount
              ?.toLocaleString("en-US", { style: "currency", currency: "GHC" })
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.message_type
              ?.toLowerCase()
              .includes(this.search.toLowerCase()) ||
            moment(item.created_at)
              .format("MM-DD-YYYY")
              ?.toString()
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.message_type
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.bundle_tracker.wallet
              ?.toString()
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.bundle_tracker.sms_balance
              ?.toString()
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.bundle_tracker?.voice_balance
              ?.toString()
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      },
      dynamicHeight() {
        if (screen.height < 749) {
          return "50vh";
        } else {
          return "55vh";
        }
      },
    },
    mounted() {
      this.loading = true;
      store.dispatch("wallet/purchaseBundleHistory").then((response) => {
        this.bundleHistory = response.data.data;
        // if bundle tracker in bundle history is null, replace with empty object
        this.bundleHistory.forEach((item) => {
          if (item.bundle_tracker == null) {
            item.bundle_tracker = {
              sms_balance: "",
              voice_balance: "",
              wallet: "",
            };
          }
        });
        this.loading = false;
      });
      // Analytics
      this.$mixpanel.track("Bundle History Page");
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Bundle History Page");
      }
    },
  };
</script>
<style scoped>
  .table-container,
  .search-container {
    margin-top: 2rem;
  }

  .complete-payment-icon {
    border: none;
  }

  .search-container {
    display: flex;
    justify-content: space-between;
  }

  .export-button {
    padding: 0.2rem 2rem;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: #f7921c;
    color: #fff;
  }

  input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.3rem;
    width: 15rem;
  }

  button:disabled {
    background-color: #d8d6d6;
  }

  button:hover:disabled {
    background-color: #d8d6d6;
    color: white;
    border-color: transparent;
  }
</style>
