var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main-container"
  }, [_c('div', {
    staticClass: "left-side-wallet"
  }, [_c('button', {
    ref: "goToWallets",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.goToWallets
    }
  }, [_vm._v(_vm._s(_vm.$t('dashboard.wallet.purchase_bundle')))])]), _c('div', {
    staticClass: "right-side-history"
  }, [_c('button', {
    ref: "goToWalletHistory",
    on: {
      "click": _vm.goToWalletHistory
    }
  }, [_vm._v(_vm._s(_vm.$t('dashboard.wallet.wallet_history')))]), _c('button', {
    ref: "goToBundleHistory",
    on: {
      "click": _vm.goToBundleHistory
    }
  }, [_vm._v(_vm._s(_vm.$t('dashboard.wallet.bundle_history')))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };